import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import urls from './data/url.json'
;(window as any).ted = {
  openTed(query?: string) {
    const payload = !query ? `open-ted` : `open-ted|${query}`
    window.postMessage(payload, '*')
  },
  predefinedQuestion: [],
}

function getRandomItems(arr: any[], num: number) {
  let result = []
  let tempArray = [...arr] // Create a copy of the array to avoid modifying the original

  if (arr.length <= num) return arr

  for (let i = 0; i < num; i++) {
    let randomIndex = Math.floor(Math.random() * tempArray.length)
    result.push(tempArray[randomIndex])
    tempArray.splice(randomIndex, 1) // Remove the selected item to avoid repetition
  }

  return result
}

window.addEventListener('message', event => {
  if (event.data.includes('sync-localstorage')) {
    console.log('sync-localstorage')
    const jsonString = event.data.split('|')[1]
    window.localStorage.setItem('ted_user_data', jsonString)
    const userData = JSON.parse(jsonString)
    const fields: any = {
      fullname: 'name',
      email: 'email',
      phoneNumber: 'phone',
      company: 'company',
      Industry_Vertical__c: 'business',
      Skala_Bisnis__c: 'business_scale',
      LoB__c: 'product',
    }
    Object.keys(fields).forEach(key => {
      const userDataKey = fields[key]
      if (userData[userDataKey]) {
        window.localStorage.setItem(key, userData[userDataKey])
      }
    })
  }

  if (event.data !== 'ted-suggestion-req') return
  const currentPath = window.location.pathname
  const questionData = (urls as any)[currentPath]
  if (questionData) {
    console.log('get data question', questionData)

    const randomData = getRandomItems(questionData.questions, 3)
    const questions = randomData.join('|')
    document
      .getElementById('ted-iframe')
      .contentWindow.postMessage(`ted-suggestion-res|${questions}`, '*')
  }
})

const BASE_IMG_URL = window.avaConfig?.baseUrl
const imagesPreload = [
  '/revamp/ripples-active.png',
  '/revamp/x.svg',
  '/revamp/ted.svg',
  '/revamp/chat.svg',
]

imagesPreload
  .map(item => `${BASE_IMG_URL}${item}`)
  .forEach((image: string) => {
    const newImage = new Image()
    newImage.src = image
    window[image] = newImage
  })

let targetRoot = document.getElementById('ted-embed-div')
if (!targetRoot) {
  targetRoot = document.createElement('div')
  targetRoot.id = 'ted-embed-div'

  targetRoot.style.cssText =
    'position: fixed; bottom: 10px; right: 10px; z-index: 100000'
}

document.body.appendChild(targetRoot)

const root = createRoot(targetRoot)
root.render(<App />)
